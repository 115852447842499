<template>
  <div class="diagrambox">
    <div class="odrbox" v-if="dorshow === 0">
      <div class="odrtitlebox">
        <el-select v-model="architectureid" placeholder="请选择" @change="drochange()">
          <el-option v-for="item in architecturelist" :key="item.id" :label="item.name"
            :value="item.id" :popper-append-to-body="false">
          </el-option>
        </el-select>
      </div>
      <div class="diagrambox-center">
        <TitleBox :titlename="titlename" />
        <div class="tabbox" v-if="architectureid === 2">
          <ul>
            <li v-for="(item,index) in tableData" :key="index" @click="clickdronelist(item,index)">
              <div class="circle"></div>
              <img src="../../assets/homeimg/icon1_1.png" alt="">
              <p>{{item.updateTime}}</p>
              <!-- <p>{{item.name}}</p>
              <p>{{item.address}}</p> -->
            </li>
          </ul>
        </div>
        <div class="tabbox" v-if="architectureid === 1">
          <ul>
            <li v-for="(item,index) in tableData" :key="index" @click="clickdronelist(item,index)">
              <div class="circle"></div>
              <img src="../../assets/homeimg/icon1_1.png" alt="">
              <p class="serialNumber">{{item.snC0de}}</p>
              <p>{{item.name}}</p>
              <p>{{item.flyState}}</p>
            </li>
          </ul>
        </div>
        <div class="paging" v-if="architectureid === 2">
          <el-pagination small background @size-change="historyChange"
            @current-change="historyCurrentChange" :current-page="this.historyobj.current"
            :page-sizes="[15]" :page-size="this.historyobj.size" layout="total, prev, pager, next"
            :total="historytotal">
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="odrbox" v-if="dorshow === 1">
      <div class="odrtitlebox">
        <el-select v-model="patrolid" placeholder="请选择" @change="patrolchange()">
          <el-option v-for="item in patrollist" :key="item.id" :label="item.name" :value="item.id"
            :popper-append-to-body="false">
          </el-option>
        </el-select>
      </div>
      <div class="diagrambox-center">
        <TitleBox :titlename="'巡护任务列表'" />
        <div class="patrolbox">
          <ul>
            <li>
              <p>时间</p>
              <p>巡护任务</p>
              <p>巡护人</p>
            </li>
            <li v-for="(item,index) in patrolData" :key="index"
              @click="clickpatrollist(item,index)">
              <p>{{item.date}}</p>
              <p>{{item.description}}</p>
              <p>{{item.player}}</p>
            </li>
          </ul>
        </div>
        <div class="paging">
          <el-pagination small background @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="Orderobj.pageNo" :page-sizes="[15]"
            :page-size="Orderobj.pageSize" layout="total, prev, pager, next" :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import api from '../visualization/api'
import TitleBox from '../visualization/components/title/index.vue'

export default {
  props: ['timenum', 'dorshow'],
  components: {
    TitleBox
  },
  data () {
    return {
      tableData: [],
      patrolData: [
      ],
      architecturelist: [
        {
          id: 1,
          name: '无人机实时巡护列表'
        },
        {
          id: 2,
          name: '无人机历史巡护列表'
        }
      ],
      patrollist: [
      ],
      titlename: '无人机历史巡护列表',
      architectureid: 2,
      patrolid: '',
      historyobj: {

        current: 1,
        size: 15
      },
      Orderobj: {
        pageNo: 1, // 页码
        pageSize: 15 // 页大小
      },
      total: null,
      historytotal: null

    }
  },
  computed: {},
  watch: {
    timenum: {
      immediate: true,
      handler (newvalue, lodvalue) {
        this.$nextTick(() => {
          // console.log(newvalue, 'zizujianop')
          if (newvalue === 1) {
            clearInterval(this.paramsTimer)
            // this.architectureid = 2
          } else if (newvalue === 0) {
            this.drochange()
          }
          // if (this.architectureid === 1) {
          // }
        })
      }
    },
    dorshow: {
      immediate: true,
      handler (newvalue, lodvalue) {
        this.$nextTick(() => {
          this.dorshow = newvalue
        })
      }
    }

  },
  methods: {
    patrolchange () {
      // this.$emit('patrolchange', item, index)
    },
    drochange () {
      if (this.architectureid === 1) {
        this.titlename = '无人机实时巡护列表'
        this.pagerealtime()
        this.paramsTimer = setInterval(() => {
          this.pagerealtime()
        }, 3000)
      } else if (this.architectureid === 2) {
        this.titlename = '无人机历史巡护列表'
        clearInterval(this.paramsTimer)
        this.pagelist()
      }
    },
    clickdronelist (item, index) {
      // console.log(item, '----')
      this.$emit('clickdronelist', item, index)
    },
    clickpatrollist (item, index) {
      this.$emit('clickpatrollist', this.Orderobj, index)
    },
    pagelist () {
      api.pageflightFecordList(this.historyobj).then(res => {
        this.tableData = res.data.records
        this.historytotal = res.data.total
      })
    },
    pagerealtime () {
      // console.log('dinghisqi ')
      axios.post(this.$constant.zsqyUrl + '/Wurenjirecord/findListRelData', {},
        { headers: { database: '11NatureReserves' } }
      )
        .then((res) => {
          this.tableData = res.data.data
        })
    },
    patrolpage () {
      api.thirdListPage(this.Orderobj).then(res => {
        // console.log(res, '===')
        this.patrolData = res.data.records
        this.total = res.data.total
      })
    },
    // 巡护分页
    handleSizeChange (val) {
      this.Orderobj.pageSize = val
      this.patrolpage()
    },
    // 巡护切换分页
    handleCurrentChange (val) {
      this.Orderobj.pageNo = val
      this.patrolpage()
    },
    // 历史分页
    historyChange (val) {
      this.historyobj.size = val
      this.pagelist()
    },
    // 历史切换分页
    historyCurrentChange (val) {
      this.historyobj.current = val
      this.pagelist()
    }
  },
  created () {
    this.textshow = true
    this.pagelist()
    this.patrolpage()
  },
  // 销毁定时器
  destroyed () {
    clearInterval(this.paramsTimer) // 关闭定时任务
    this.paramsTimer = null
  }
}
</script>
<style lang="less" scoped>
.diagrambox {
  width: 100%;
  height: 100%;
  background: transparent;
  /deep/.el-input__inner {
    background-color: rgba(230, 252, 255, 0.3);
    border: none;
    color: #bfdafd;
    font-size: 12px;
    border-radius: 0px;
  }
  .odrbox {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .odrtitlebox {
      margin: 3% 2%;
    }
    .diagrambox-center {
      width: 100%;
      height: 100%;
      // background: #83b4ef;
      margin: 10px 0 1% 3%;
      overflow: hidden;
      font-size: 12px;
      font-family: SourceHanSansCN;
      font-weight: 400;
      color: #e6fcff;
      .tabbox {
        width: 100%;
        height: 73%;
        margin-top: 15px;
        ul {
          width: 100%;
          height: 100%;
          overflow-y: auto;
          li {
            cursor: pointer;
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            .circle {
              width: 6px;
              height: 6px;
              background: #ffffff;
              border-radius: 50%;
            }
            img {
              width: 26px;
              height: 19px;
            }
            p {
              line-height: 30px;
              color: #fff;
              margin: 0;
            }
            .serialNumber {
              width: 40%;
              text-align: center;
              // background: #0090ff;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
            }
          }
          // li:nth-child(2n) {
          //   background: RGBA(56, 74, 97, 0.2);
          // }
          li:hover {
            background: rgba(79, 119, 163, 0.4);
          }
        }
        /* 设置滚动条的样式 */
        ::-webkit-scrollbar {
          width: 8px;
          height: 8px;
          background: RGBA(56, 74, 97, 0.2);
        }
        /* 滚动条滑块 */
        ::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: RGBA(56, 74, 97, 0.6);
          -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
        }
      }
      .patrolbox {
        width: 100%;
        height: 100%;
        // background: #0090ff;
        margin-top: 15px;
        ul {
          width: 100%;
          height: 100%;
          overflow-y: auto;
          li {
            cursor: pointer;
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            p {
              width: 25%;
              text-align: center;
              line-height: 30px;
              color: #fff;
              margin: 0;
              font-size: 10px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
            }
          }
          li:hover {
            background: rgba(79, 119, 163, 0.4);
          }
        }
        /* 设置滚动条的样式 */
        ::-webkit-scrollbar {
          width: 8px;
          height: 8px;
          background: RGBA(56, 74, 97, 0.2);
        }
        /* 滚动条滑块 */
        ::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: RGBA(56, 74, 97, 0.6);
          -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
        }
      }
      .paging {
        position: absolute;
        bottom: 2%;
        left: 0;
        width: 96%;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        /deep/.el-input__inner {
          background: transparent;
          border: 1px solid #abdaff;
          color: #fff;
        } //input背景颜色
        /deep/.btn-prev {
          background: transparent;
          color: #fff;
        } //左箭头按钮背景
        /deep/.btn-next {
          background: transparent;
          color: #fff;
        } //右箭头按钮背景
        /deep/.number {
          background: transparent;
          color: #fff;
        } //未被选中的页码背景
        /deep/.active {
          background: #0090ff !important;
          color: #fff;
        } //被选中的页码背景
        /deep/.el-pagination.is-background .el-pager li {
          background: transparent;
          color: #fff;
        } //被折叠的页码背景
        /deep/.el-pagination__total {
          color: #fff;
        } //总条数字体颜色
        /deep/.el-pagination__jump {
          color: #fff;
        } //前往第几页数字体颜色
      }
    }
  }
}
</style>
